const Footer = () => {
  return (
    <div className="flexBoxCol" style={{ textAlign: 'center' }}>
      <a href={`/privacy`}>Privacy Policy</a>
      <a href={`/terms`}>Terms of Use</a>
    </div>
  )
}

export default Footer
